<template>
  <div class="content_thank custom_pt">
    <div class="container">
      <h3>{{ $t("order.thank_you") }} {{ user.first_name }}!</h3>
      <div class="like_block">
        <img alt="" src="/img/icon.svg" />
        <img alt="" src="/img/icon.svg" />
        <img alt="" src="/img/icon.svg" />
      </div>
      <img alt="" class="thank-img mw-100" src="/img/Thank-you.gif" />
      <template v-if="order">
        <p class="thank__order_id">Order ID: {{ order.id }}</p>
        <p class="delevery_info__thank">
          <span v-if="order.is_pickup">{{ $t("pickup_on_site") }}</span>
          <span v-else>{{ $t("delivery_at_home") }}</span>
          <br />

          <template v-if="order.is_pickup">
            {{ $t("pickup_address") }}:
            <b>{{ order.restaurants_address[0] }}</b>
          </template>
          <template v-else>
            {{ $t("delivery_address") }}: <b>{{ order.address.short }}</b>
          </template>
        </p>
        <p class="time__delevery">
          {{ order.deliver_at }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      order: {
        address: {}
      }
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    orderStatus() {
      return this.order.status === "payment"
        ? this.$t("order.status.payment")
        : this.$t("order.status.completed");
    }
  },

  mounted() {
    document.body.classList.add("favorits_page_restoran");
  },

  destroyed() {
    document.body.classList.remove("favorits_page_restoran");
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http
        .get(`/api/user/orders/${this.$route.params.order}`)
        .then(response => {
          this.order = response.data.data;
          this.$gtag.event("conversion", {
            send_to: `${process.env.VUE_APP_GTAG_ID}/${process.env.VUE_APP_GTAG_CONVERSATION_ID}`,
            value: this.order.invoice.total,
            currency: "EUR",
            transaction_id: this.order.invoice.transaction_id
          });
        });
    }
  },

  metaInfo() {
    return {
      title: this.$t("order.title")
    };
  }
};
</script>
