<template>
  <div>
    <div v-if="showError" id="modal_sorry" class="modal_home">
      <div class="content_modal_home">
        <button class="close__modal" @click="showError = false"></button>
        <div class="warring_text">
          <span>{{ errors }}</span>
        </div>
        <div class="btn_block__modal mb-0">
          <button class="nav__btn" @click="showError = false">
            OK
          </button>
        </div>
      </div>
    </div>
    <div style="text-align: center;margin: 110px auto -110px auto;font-size: 18px;">
      <div>{{ $t("order.payment_notice") }}</div>
    </div>
    <div class="payment-container">
      <div id="paymentForm" v-if="this.order.payment_form_token"></div>
    </div>
  </div>
</template>

<script>
import KRGlue from "@lyracom/embedded-form-glue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      showError: false,
      errors: "",
      order: {}
    };
  },

  created() {
    this.getData();
  },

  mounted() {
    document
      .getElementsByClassName("cart__header")[0]
      .classList.add("disabled");
    document.body.classList.remove("page");
  },

  destroyed() {
    document
      .getElementsByClassName("cart__header")[0]
      .classList.remove("disabled");
    document.body.classList.add("page");
    KRGlue.loadLibrary().then(({ KR }) => KR.removeForms());
  },

  methods: {
    ...mapActions("cart", ["deleteCart", "updateBalance"]),
    getData() {
      this.$http
        .get(`/api/user/orders/${this.$route.params.order}`)
        .then(response => {
          this.order = response.data.data;
          if (
            this.order.payment_form_token == null &&
            this.order.invoice.total == this.order.invoice.use_balance
          ) {
            return this.completeWithoutPay();
          } else if (this.order.payment_form_token == 'payed' || typeof this.order.payment_form_token == 'undefined') {
            return this.completeWithoutPay();
          } else if (this.order.payment_form_token == null) {
            this.showError = true;
            this.errors =
                "There is a problem with your account. Please contact our customer support +377 93104248";
          } else if (this.order.status !== "payment") {
            return this.redirectToCompleted();
          } else this.loadPaymentForm();
        });
    },
    loadPaymentForm() {
      KRGlue.loadLibrary(
        process.env.VUE_APP_SYSTEMPAY_ENDPOINT,
        process.env.VUE_APP_SYSTEMPAY_PUBLIC_KEY
      )
        .then(({ KR }) =>
          KR.setFormConfig({
            formToken: this.order.payment_form_token,
            "kr-language": this.$t("order.kr_locale")
          })
        )
        .then(({ KR }) => KR.addForm("#paymentForm"))
        .then(({ KR, result }) => KR.showForm(result.formId))
        .then(({ KR }) => KR.onSubmit(this.onPaid));
    },
    onPaid(event) {
      if (event.clientAnswer.orderStatus === "PAID") {
        this.deleteCart().then(() => {
          this.$cookies.set("lock_mode", false, Infinity);
          this.redirectToCompleted();
        });
      }
    },
    completeWithoutPay() {
      this.updateBalance(this.$route.params.order).then(() => {
        this.deleteCart().then(() => {
          this.$cookies.set("lock_mode", false, Infinity);
          this.redirectToCompleted();
        });
      });
    },
    redirectToCompleted() {
      this.$router.push({ name: "order", params: { order: this.order.id } });
    }
  },

  metaInfo() {
    return {
      title: this.$t("order.title")
    };
  }
};
</script>

<style scoped>
.payment-container {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 70px 0;
  margin-top: 90px;
}
</style>
